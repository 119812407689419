import React, { useEffect, useState } from "react";
import { Button, Box, Grid, useTheme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { FormProvider, useForm } from "react-hook-form";
import { CustomInputField } from "./CustomInputField.jsx";

export const FormDynamic = (props) => {
  const theme = useTheme();
  const formMethods = useForm();
  const { control, handleSubmit, setValue, formState, register, reset, watch, getValues } =
    useForm({ mode: "onBlur" });
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (props.DataControls !== undefined && props.DataControls !== null) {
      setQuestions([]);
      setQuestions(props.DataControls);
    }
  }, [props.DataControls]);

  useEffect(() => {
    if (props.RessetForm === true) {
      props.setRessetForm(null);
      reset();
      HandleChangeValues();
    }
  }, [props.RessetForm]);

  useEffect(() => {
    if (questions.length > 0) {
      HandleChangeValues();
    }
  }, [questions]);

  let HandleChangeValues = () => {
    questions?.forEach((itemQuestion) => {
      setValue(itemQuestion.Id, itemQuestion.Value);
    });
  };

  useEffect(() => {
    console.log('activeGetValues', props?.activeGetValues)
    if (props?.activeGetValues) {
      console.log('getValues', getValues())
      return props.setValuesDataForm(getValues());
    } 
  }, [watch, props?.activeGetValues]);


  return (
    <FormProvider {...formMethods} theme={theme} >
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        {questions.length > 0 && (
          <Box
            sx={{
              display: "flex !important",
              flexDirection: "column !important",
              alignItems: "center !important",
              position: "relative",
              width: "100% !important",
              padding: 3,
              overflowY: "auto",
              ["@media only screen and (max-width: 812px)"]: {
                height: "60vh",
              },
            }}
          >
            {questions?.map((itemInput, index) => {
              switch (itemInput.DataType.toUpperCase()) {
                case "PHONE":
                  return (
                    <CustomInputField
                      inputType="phone"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                    />
                  );
                case "RADIO":
                  return (
                    <CustomInputField
                      inputType="radio"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      radioValues={itemInput.Options}
                      required={itemInput.Required}
                      validations={{ required: true }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                    />
                  );
                case "COMBO":
                  return (
                    <CustomInputField
                      inputType="select"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      optionsList={itemInput.Options}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                    />
                  );
                case "DATE":
                  return (
                    <CustomInputField
                      inputType="date"
                      register={register}
                      control={control}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                      defaultValue={""}
                    />
                  );
                case "DATETIME":
                  return (
                    <CustomInputField
                      inputType="dateTimer"
                      register={register}
                      control={control}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                      defaultValue={""}
                    />
                  );
                case "CHECKBOX":
                  return (
                    <CustomInputField
                      inputType="check"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: true }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                    />
                  );
                case "CURRENCY":
                  return (
                    <CustomInputField
                      inputType="moneda"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                    />
                  );
                case "EMAIL":
                  return (
                    <CustomInputField
                      inputType="email"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                    />
                  );
                case "CLABE":
                  return (
                    <CustomInputField
                      inputType="clabe"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                    />
                  );
                case "RFC":
                  return (
                    <CustomInputField
                      inputType="rfc"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                    />
                  );
                case "CURP":
                  return (
                    <CustomInputField
                      inputType="curp"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                    />
                  );
                case "NUMBER":
                  return (
                    <CustomInputField
                      inputType="numberDecimal"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                    />
                  );

                case "NUMBERINT":
                  return (
                    <CustomInputField
                      inputType="number"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                    />
                  );
                case "POSTALCODE":
                  return (
                    <CustomInputField
                      inputType="PostalCode"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                    />
                  );
                case "PASSWORD":
                  return (
                    <CustomInputField
                      inputType="Password"
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                      isPassword={true}
                    />
                  );
                default:
                  return (
                    <CustomInputField
                      inputType="text"
                      placeholder={itemInput.placeholder ?? ""}
                      control={control}
                      register={register}
                      name={`${itemInput.Id}`}
                      label={itemInput.Name}
                      validations={{ required: itemInput.Required }}
                      defaultValue={itemInput.Value}
                      error={!!formState.errors[`${itemInput.Id}`]}
                      helperText={formState.errors[`${itemInput.Id}`]?.message}
                      required={itemInput.Required}
                    />
                  );
              }
            })}
          </Box>
        )}
        {questions.length > 0 ? (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ mt: 1, mb: 3 }}
          >
            {props.CancelButtonText === false ? null : (
              <Grid item xs={12} sm={11}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  sx={{  width: "100%" }}
                  onClick={() => {
                    props.handleCancel();
                  }}
                >
                  {props.ConfirmButtonText === undefined ||
                  props.ConfirmButtonText === null
                    ? "Cancelar"
                    : props.CancelButtonText}
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sm={11}>
              <Button
                disabled={!formState.isValid}
                fullWidth
                variant="contained"
                sx={{ width: "100%" }}
                onClick={handleSubmit(props.handleConfirm)}
              >
                {props.ConfirmButtonText === undefined ||
                props.ConfirmButtonText === null
                  ? "Siguiente"
                  : props.ConfirmButtonText}
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </LocalizationProvider>
    </FormProvider>
  );
};

import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Grid, Button } from "@mui/material";
import { FormDynamic, Alert } from "../../../components";
import BackdropComponent from "../../../components/BackDrop";
import {
  RegisterDocumentFields,
  IntegrateDocumentToSign,
} from "../../../store/actions";
import { TemplatesServices } from "../../../services/TemplatesServices";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Icon } from "@iconify/react/dist/iconify.js";
import { da } from "date-fns/locale";

export const DynamicQuestionnaire = (props) => {
  const dispatch = useDispatch();
  const [listDocumentFieldData, setlistDocumentFieldData] = useState([]);
  const [valuesDataForm, setValuesDataForm] = useState([]);
  const [activeGetValues, setActiveGetValues] = useState(false);
  const [documentSeletedCurrent, setDocumentSeletedCurrent] = useState(null);
  const [blockedDataSend, setBlockedDataSend] = useState(false);
  const userLogoutClose = true;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const rolUser = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.SecurityLoginData?.Roles[0]
        ?.Code
  );
  const DataStage = useSelector((state) => state.File?.item);
  const DataStageDocuments = useSelector((state) =>
    state.File?.item?.Documents.filter(
      (item) =>
        item.DocumentData.DocumentFields &&
        item.DocumentData.DocumentFields.length > 0
    )
  );

  const RegisterDocumentFieldsState = useSelector(
    (state) => state.RegisterDocumentFieldsData
  );
  const folioIdSolicitudState = useSelector(
    (state) => state.File?.item?.FileData?.Id
  );
  const IntegrateDocumentToSignState = useSelector(
    (state) => state.IntegrateDocumentToSignData
  );

  const folioIdSolicitud =
    folioIdSolicitudState ?? sessionStorage.getItem("FileId");

  const validationUserSign = () => {
    let userCurso = DataStage.FileData.CurrentUser;
    // Usuarioo en curso si sea firmante
    const usuarioCursoSignCapture = DataStage.Participants.filter(
      (usuario) => usuario.Capture && userCurso === usuario.Email
    );
    // Para validar el maximo numero de firmante
    const maxCaptureOrder = Math.max(
      ...DataStage.Participants.map((participante) => participante.CaptureOrder)
    );
    // Para validar el minimo numero de firmante
    const minSignOrder = Math.min(
      ...DataStage.Participants.map((participante) => participante.SignOrder)
    );
    // Validar si el usuario en curso en el primer firmante
    const usuarioEnCursoPrimerFirmante =
      usuarioCursoSignCapture[0]?.SignOrder === minSignOrder;
    // Validar que el usuario en curso sea el ultimo capturista en caso de si no es el ultimno sacarlo alv
    const usuarioEnCursoUltimoCapturista =
      usuarioCursoSignCapture[0]?.CaptureOrder === maxCaptureOrder;
    //LOGICA DE SWTICH
    // Es el ultimo usuario en capturar y el primero en firmar, si es asi asi, tambien valida que sea el primero en firmar en caso contrario botalo alv
    if (
      usuarioEnCursoUltimoCapturista === true &&
      usuarioEnCursoPrimerFirmante === true
    ) {
      return props.setValidateReload(true);
    } else {
      // Botalo pa fuera papito no es el ultimo en capturar, y valida el tema de si es operador o es un super admin
      if (rolUser === "Operador" && userLogoutClose === true) {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/auth/login");
        window.location.reload();
      } else {
        navigate("/inbox/files");
      }
    }
    // Escnario 1 y simple
    // La condicion es si es == 1 por que es unico en ambos lados, una por que es unico y dos por que si coincidio con el tema de ser firmante... , pero debo validar si es el proximo firmante  por que podria firmar en
    // pocision numero dos en lugar de ser numero
    // Escenario 2
    // Es el ultimo usuario en capturar de 3 detectar que efectivameente sea el ultimo en capturar, al mismo tiempo detectar que sea el primero en firmar por ejemplo
    // Dos validaciones, saber si es el ultimo en capturar con el order, y saber si es el primero en firmar con el signOrder
  };

  useEffect(() => {
    if (
      RegisterDocumentFieldsState.items !== undefined &&
      RegisterDocumentFieldsState.items !== null &&
      loading
    ) {
      if (props.TemplateData === true) {
        dispatch(RegisterDocumentFields("", true));
        setLoading(false);
        Alert({
          icon: "success",
          text: "Datos guardados correctamente.",
          okbtntext: "Aceptar",
        });
      } else {
        dispatch(RegisterDocumentFields("", true));
        dispatch(
          IntegrateDocumentToSign({
            File_Id: folioIdSolicitud,
            IsMasive: false,
          })
        );
      }
    } else if (
      RegisterDocumentFieldsState.error !== undefined &&
      RegisterDocumentFieldsState.error !== null &&
      loading
    ) {
      setLoading(false);
      dispatch(RegisterDocumentFields("", true));
      Alert({
        icon: "error",
        text: RegisterDocumentFieldsState.error,
        okbtntext: "Aceptar",
      });
    }
  }, [RegisterDocumentFieldsState]);

  useEffect(() => {
    if (props.TemplateData === true) {
      return;
    }
    if (
      IntegrateDocumentToSignState.items !== undefined &&
      IntegrateDocumentToSignState.items !== null &&
      loading
    ) {
      setLoading(false);
      dispatch(IntegrateDocumentToSign("", true));
      Alert({
        icon: "success",
        text: "Datos guardados correctamente.",
        okbtntext: "Aceptar",
      });
    } else if (
      IntegrateDocumentToSignState.error !== undefined &&
      IntegrateDocumentToSignState.error !== null &&
      loading
    ) {
      setLoading(false);
      dispatch(IntegrateDocumentToSign("", true));
      Alert({
        icon: "error",
        text: IntegrateDocumentToSignState.error,
        okbtntext: "Aceptar",
      });
    }
  }, [IntegrateDocumentToSignState]);

  const handleConfirm = (formData) => {
    setLoading(true);
    const MetaDataFormat = listDocumentFieldData.map((itemField) => {
      return {
        Document_Id: documentSeletedCurrent?.DocumentData?.Id,
        Code: itemField.Code,
        Value: reformateData(itemField, formData),
      };
    });
    let request = {
      File_Id: folioIdSolicitud,
      DocumentFieldsUpdate: MetaDataFormat,
      IsMasive: false,
      ContinueWorkFlow: DataStageDocuments?.length === 1 ? true : false,
    };
    dispatch(RegisterDocumentFields(request));

    if (DataStageDocuments?.length === 1) validationUserSign();
    else {
      const currentIndex = DataStageDocuments.findIndex(
        (doc) => doc.DocumentData.Id === documentSeletedCurrent.DocumentData.Id
      );
      if (currentIndex !== -1) {
        if (currentIndex < DataStageDocuments.length - 1) {
          selectedDocument(DataStageDocuments[currentIndex + 1]);
        } else {
          selectedDocument(DataStageDocuments[0]);
        }
      }
    }
  };

  const reformateData = (itemField, formData) => {
    let dataValue = formData[`${itemField.Name}_${itemField.DocumentField_Id}`];

    if (itemField.DataType === "Currency") {
      return "$" + dataValue;
    } else if (
      itemField.DataType === "DateTime" &&
      dataValue !== null &&
      dataValue !== "" &&
      dataValue !== undefined
    ) {
      return moment.utc(dataValue).local().format("DD-MM-YYYY hh:mm:ss A");
    } else if (
      itemField.DataType === "Date" &&
      dataValue !== null &&
      dataValue !== "" &&
      dataValue !== undefined
    ) {
      return moment.utc(dataValue).local().format("DD-MM-YYYY");
    } else {
      return dataValue;
    }
  };

  const handleCancel = (item) => {
    props.setIsCuestionaryActive(false);
  };

  const selectedDocument = async (item) => {
    try {
      setLoading(true);
      const templateService = TemplatesServices.getInstance();
      const response = await templateService.GetFieldsCaptureParticipant({
        File_Id: folioIdSolicitudState,
      });

      setDocumentSeletedCurrent(item);
      let documentSelected = response.Body.Documents.find(
        (doc) => doc.DocumentData.Id === item.DocumentData.Id
      );

     /*  let matchingFields = [];
      matchingFields = documentSelected.DocumentData.DocumentFields.filter((field) =>
        response.Body.DocumentFields.some((responseField) => responseField.Code === field.Code)
      );
 */
      let previewData = documentSelected.DocumentData.DocumentFields.filter(
        (field, index, self) =>
          index ===
          self.findIndex(
        (docField) => docField.Code === field.Code
          )
      ); 

      let matchingFields = [];
      matchingFields = previewData.filter((field) =>
        response.Body.DocumentFields.some((responseField) => responseField.Code === field.Code)
      );

      let newArrayDocs = matchingFields?.map((item) => {
        return {
          ...item,
          Id: `${item.Name}_${item.DocumentField_Id}`,
        };
      });

      const allFieldsValid = response.Body.DocumentFields.every(
        (field) => field.Required && field.Value !== null && field.Value !== ""
      );
      setBlockedDataSend(!allFieldsValid);
      setlistDocumentFieldData(newArrayDocs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const handleFinish = () => {
    setLoading(true);
    setActiveGetValues(true);
  };

  useEffect(() => {
    if (
      activeGetValues &&
      valuesDataForm &&
      Object.keys(valuesDataForm).length > 0
    ) {
      setActiveGetValues(false);
      const MetaDataFormat = listDocumentFieldData.map((itemField) => {
        return {
          Document_Id: documentSeletedCurrent?.DocumentData?.Id,
          Code: itemField.Code,
          Value: reformateData(itemField, valuesDataForm),
        };
      });

      let request = {
        File_Id: folioIdSolicitud,
        DocumentFieldsUpdate: MetaDataFormat,
        IsMasive: false,
        ContinueWorkFlow: true,
      };
      setLoading(false);
      dispatch(RegisterDocumentFields(request));
      validationUserSign();
    }
  }, [activeGetValues, valuesDataForm]);

  return (
    <Fragment>
      <BackdropComponent loading={loading} />
      <Grid
        container
        justifyContent={"rigth"}
        sx={{ mb: 1, pb: 1, borderBottom: "1px solid #898B8D4D" }}
      >
        <Grid item xs={12} md={10} lg={10}>
          <Box sx={{ mt: 1.5 }}>
            <Typography variant="caption" sx={{ fontWeight: 500, mb: 2 }}>
              Estatus:{" "}
              <span style={{ color: " #C20E30" }}>Firma de documentos </span>
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={3}>
          <Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="caption">Captura de información</Typography>
              <Typography variant="body2" sx={{ fontWeight: 300, mt: 2 }}>
                Selecciona el documento al que deseas capturar la información y
                asegúrate de completar todos los campos requeridos
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            {DataStageDocuments?.length > 0
              ? DataStageDocuments.map((item, index) => (
                  <ButtonAS
                    key={index}
                    item={item}
                    documents={DataStageDocuments}
                    index={index}
                    setLoading={setLoading}
                    selectedDocument={selectedDocument}
                    documentSeletedCurrent={documentSeletedCurrent}
                  />
                ))
              : null}
          </Box>
          {DataStageDocuments?.length === 1 ? null : (
            <Box>
              <Button
                color="primary"
                variant="contained"
                sx={{ mt: 2, width: "90%" }}
                disabled={blockedDataSend}
                onClick={() => {
                  handleFinish();
                }}
              >
                Finalizar captura
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item lg={9}>
          <Grid container>
            <Grid item xs={10} md={10} sx={{ mt: 1, ml: 3 }}>
              <Typography variant="body1" sx={{ fontWeight: 300 }}>
                La información capturada será utilizada para llenar el documento
                que será firmado
              </Typography>
            </Grid>
            {listDocumentFieldData?.length > 0 ? (
              <FormDynamic
                DataControls={listDocumentFieldData}
                handleConfirm={handleConfirm}
                ConfirmButtonText={"Guardar"}
                handleCancel={handleCancel}
                CancelButtonText={false}
                setValuesDataForm={setValuesDataForm}
                activeGetValues={activeGetValues}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const ButtonAS = ({
  item,
  documents,
  index,
  selectedDocument,
  documentSeletedCurrent,
  setLoading,
}) => {
  const active = (item) => {
    return documentSeletedCurrent?.DocumentData?.Id === item?.DocumentData?.Id;
  };

  let stateAprovatte = item?.DocumentData?.Validation?.Approved;

  useEffect(() => {
    setLoading(true);
    selectedDocument(documents[0]);
  }, []);

  return (
    <Button
      sx={(theme) => ({
        color: active(item) ? "#95CFD6" : "#C1C1C1",
        background: active(item) ? "#F2FDFF" : "#F7F8FA",
        width: "90%",
        borderRadius: 2,
        borderStyle: "solid",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        padding: 0,
        border: active(item) ? "1.5px solid #95CFD6" : "none",
        minHeight: 70,
        marginBottom: "1em",
      })}
      onClick={() => selectedDocument(item)}
    >
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
          }}
        >
          <Icon icon="solar:document-add-broken" fontSize={50} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Box sx={{ flexGrow: 8, ml: 1 }}>
            <Typography
              align="left"
              sx={(theme) => ({
                fontSize: "12px !important",
                fontWeight: 550,
                color: stateAprovatte
                  ? "#000"
                  : active(item)
                  ? "#000"
                  : theme.palette.secondary.main80,
                // ACOMODAR AQUI LOS COLORES
              })}
            >
              Documento {index + 1}
            </Typography>
            <Typography
              align="left"
              sx={(theme) => ({
                fontSize: "12px !important",
                textTransform: "capitalize",
                color: stateAprovatte
                  ? "#28A745"
                  : active(item)
                  ? "#000"
                  : theme.palette.secondary.main80,
              })}
            >
              {item.FileName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Button>
  );
};
